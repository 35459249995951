define("discourse/plugins/discourse-locations/discourse/templates/discovery/map", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Discovery::Layout
    @model={{this.model}}
    @createTopicDisabled={{this.createTopicDisabled}}
  >
    <:navigation>
      <Discovery::Navigation
        @category={{this.model.category}}
        @tag={{this.model.tag}}
        @additionalTags={{this.additionalTags}}
        @filterType={{this.model.filterType}}
        @noSubcategories={{this.model.noSubcategories}}
        @canBulkSelect={{this.canBulkSelect}}
        @bulkSelectHelper={{this.bulkSelectHelper}}
        @createTopic={{this.createTopic}}
        @createTopicDisabled={{this.createTopicDisabled}}
        @canCreateTopicOnTag={{this.canCreateTopicOnTag}}
        @toggleTagInfo={{this.toggleTagInfo}}
        @tagNotification={{this.tagNotification}}
      />
    </:navigation>
  
    <:list>
      <div class="map-component map-container">
        <LocationsMap @mapType="topicList" />
      </div>
    </:list>
  </Discovery::Layout>
  */
  {
    "id": "WR63Oc5H",
    "block": "[[[8,[39,0],null,[[\"@model\",\"@createTopicDisabled\"],[[30,0,[\"model\"]],[30,0,[\"createTopicDisabled\"]]]],[[\"navigation\",\"list\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@category\",\"@tag\",\"@additionalTags\",\"@filterType\",\"@noSubcategories\",\"@canBulkSelect\",\"@bulkSelectHelper\",\"@createTopic\",\"@createTopicDisabled\",\"@canCreateTopicOnTag\",\"@toggleTagInfo\",\"@tagNotification\"],[[30,0,[\"model\",\"category\"]],[30,0,[\"model\",\"tag\"]],[30,0,[\"additionalTags\"]],[30,0,[\"model\",\"filterType\"]],[30,0,[\"model\",\"noSubcategories\"]],[30,0,[\"canBulkSelect\"]],[30,0,[\"bulkSelectHelper\"]],[30,0,[\"createTopic\"]],[30,0,[\"createTopicDisabled\"]],[30,0,[\"canCreateTopicOnTag\"]],[30,0,[\"toggleTagInfo\"]],[30,0,[\"tagNotification\"]]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[10,0],[14,0,\"map-component map-container\"],[12],[1,\"\\n      \"],[8,[39,5],null,[[\"@mapType\"],[\"topicList\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]]],[],false,[\"discovery/layout\",\":navigation\",\"discovery/navigation\",\":list\",\"div\",\"locations-map\"]]",
    "moduleName": "discourse/plugins/discourse-locations/discourse/templates/discovery/map.hbs",
    "isStrictMode": false
  });
});