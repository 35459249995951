define("discourse/plugins/discourse-locations/discourse/connectors/topic-list-after-badges/location-label", ["exports", "@glimmer/component", "discourse/plugins/discourse-locations/discourse/components/location-label-container", "@ember/component", "@ember/template-factory"], function (_exports, _component, _locationLabelContainer, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-empty-glimmer-component-classes */

  class LocationLabel extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @outletArgs.topic.location}}
          <span class="location-after-title">
            <LocationLabelContainer
              @topic={{@outletArgs.topic}}
              @parent="topic-list"
            />
          </span>
        {{/if}}
      
    */
    {
      "id": "xiIalBK7",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"topic\",\"location\"]],[[[1,\"      \"],[10,1],[14,0,\"location-after-title\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@topic\",\"@parent\"],[[30,1,[\"topic\"]],\"topic-list\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-locations/discourse/connectors/topic-list-after-badges/location-label.js",
      "scope": () => [_locationLabelContainer.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LocationLabel;
});